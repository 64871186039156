import { useEffect, useState } from "react";
import {
  getSparePartDetails,
  getSQ01ByID,
  postSpBasket,
  getSpBasketID,
} from "../model/GSMainPage";
import { useLocation } from "react-router";
import Logger from "../../../basecomp/base/Logger";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

function useSparePartInfoViewModel() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const material_no =
    decodeURIComponent(searchParams.get("material_no")) || null;
  const [materialDetails, setMaterialDetails] = useState();
  const [completeData, setCompleteData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState("");
  const [spBasketId, setSpBasketId] = useState("");
  const { handleToastOpen } = useToast();

  useEffect(() => {
    if (material_no) {
      MaterialData();
    }
  }, []);

  //Fetch material details
  const MaterialData = async () => {
    try {
      console.log(material_no);
      
      const response = await getSparePartDetails(material_no);
      if(response.data.status===204){
      handleToastOpen("error", "Material is not present");
      setMaterialDetails([]);

      }
      console.log(response.data,'mat');
      
      setMaterialDetails(response.data);
    } catch (error) {
      handleToastOpen("error", error.message);
      Logger.printStackTrace(error);
    }
  };

  //check quantity should be numeric
  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Numeric regex pattern
    const numericPattern = /^[0-9]*$/;

    if (numericPattern.test(inputValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      setQuantity("");
      setError("Value must be numeric");
    }
  };

  // Function to fetch row data based on ID from sq01
  const fetchRowData = async (id) => {
    try {
      const response = await getSQ01ByID(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //fetch Sq01 row data on click
  const handleOpenModal = async (id) => {
    try {
      // Fetch the complete data for the selected row
      console.log("sq01 id:  ", id);
      const completeData = await fetchRowData(id);

      console.log("complete sq01 data:     ", completeData);
      // Update the state with completeData
      setCompleteData(completeData);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  //fetch spbasket id

  const fetchSpBasketID = async () => {
    try {
      const response = await getSpBasketID();
      const data = await response.data;

      if (data) {
        console.log("Sp basket id: ", data.sp_basket_id);
        setSpBasketId((prevSpBasketID) => {
          console.log("set sp basket id  to state: ", data.sp_basket_id);
          return data.sp_basket_id;
        });
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };

  useEffect(() => {
    fetchSpBasketID();
  }, []);

  const addToCart = async () => {
    console.log("inside addToCart", completeData);
    console.log("end user:  ", quantity);
    console.log("ohq:  ", completeData[0].ohqty);
    const qty_val = completeData[0].ohqty - quantity;
    console.log("calculated qty", qty_val);
    const status_qty = qty_val >= 0 ? 1 : 0;
    console.log("Status: ", status_qty);
    const storage_bin_loc =
      completeData[0].storage_loc +
      (completeData[0].bin_location === null
        ? ""
        : " / " + completeData[0].bin_location);

    console.log("completeData", completeData);

    // Prepare the data to send as an array
    const data = [
      {
        // Other fields...
        id: spBasketId,
        oem_no: "", // Use an empty string as a default if oem_no is null
        oem_description: "", // Use an empty string as a default if oem_description is null
        notes: "", // Use an empty string as a default if notes is null
        quantity: quantity || "", // Use an empty string as a default if quantity is null
        uom: completeData[0].b_un || "", // Use an empty string as a default if uom is null
        material_no: completeData[0].material_no || "", // Use an empty string as a default if material_no is null
        global_material_no: "", // Use an empty string as a default if gbl material_no is null
        global_material_desc: "", // Use an empty string as a default if glb material_desc is null
        storage_bin: storage_bin_loc || "", // Use an empty string as a default if storage_bin is null
        status: status_qty, // Use an empty string as a default if status is null
        job_id: null, // Use an empty string as a default if job id is null
        type: "SB" || "", // Default value "SB" if type is not provided
        gsone_type: "gsone_add", //Default value if the adding record from Gsone
      },
    ];

    console.log("postaddtocart", data);

    try {
      // Send the data to the API endpoint using Axios

      const response = await postSpBasket(data);

      if (response.error) {
        // Error occurred while saving data
        console.error("Error occurred while saving data:", data.error);
      } else {
        // Data saved successfully
        // handleToastOpen("success", "Data added successfully");
        alert("Data added successfully");
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };

  return {
    materialDetails,
    modalOpen,
    quantity,
    error,
    setModalOpen,
    handleOpenModal,
    handleChange,
    addToCart,
  };
}

export default useSparePartInfoViewModel;
