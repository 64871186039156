import { useEffect } from "react";
import { checkAssetType } from "../model/GSMainPage";
import Logger from "../../../basecomp/base/Logger";
import { useNavigate } from "react-router-dom";

function useGsOneMainPageViewModel() {
  const navigate = useNavigate();
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const asset_id =
    decodeURIComponent(searchParams.get("asset_id")) !== "null"
      ? decodeURIComponent(searchParams.get("asset_id"))
      : "";

  useEffect(() => {
    assetCheck();
  }, [asset_id]);

  const assetCheck = async () => {
    try {
      const response = await checkAssetType(asset_id);
      const type = response.data.type;
        if (type === "F") {
          navigate(`/warehouse/asset-info?asset_id=${asset_id}`);
        } else if (type === "S") {
          navigate(
            `/warehouse/spare-part-info?material_no=${response.data.material_no}`
          );
    
      }
    } catch (error) {
      Logger.printStackTrace(error);
    }
  };

  return { asset_id };
}

export default useGsOneMainPageViewModel;
