import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const currentUrl = window.location.origin;
const {
  axiosGetCallWithToken,
  axiosPostCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallWithTokenHeader,
  axiosGetCallHeaderWithToken,
  axiosPutCallWithToken
} = AxiosWrapper;

export const fetchSizeOptions = async () => {
  let response;
  try {
    response = await axiosGetCallWithToken(
      `${Base_URL}/label_print/getAllMeasurement`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const fetchPoOrMaterial = async (poNo, matNo) => {
  let response;
  try {
    response = await axiosGetCallWithToken(
      `${Base_URL}/label_print/getPoNoOrMaterial?po_no=${poNo}&mat_no=${matNo}`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const addSticker = async (stickerData) => {
  let response;
  try {
    response = await axiosPostCallWithToken(
      `${Base_URL}/label_print/addSticker`,
      stickerData
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const updateSticker = async (id, material_no, bin_location, bulk_location, print_qty) => {
  let response;
  try {
    response = await axiosPutCallWithToken(
      `${Base_URL}/label_print/updateSticker?id=${id}&material_no=${material_no}&bin_location=${bin_location}&bulk_location=${bulk_location}&print_qty=${print_qty}`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const fetchStickers = async () => {
  let response;
  try {
    response = await axiosGetCallWithToken(
      `${Base_URL}/label_print/getAllSticker`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const deleteSticker = async (id) => {
  let response;
  try {
    const idParam = id === undefined ? `` : `?id=${id}`;
    response = await axiosDeleteCallWithToken(
      `${Base_URL}/label_print/deleteSticker${idParam}`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const getStickerPdf = async (id, stickerData, type) => {
  let response;
  try {
    const idParam = id === undefined ? `` : `&id=${id}`;
    const stickerDataParam =
      stickerData === undefined
        ? ``
        : `&stickerData=${JSON.stringify(stickerData)}`;
    response = await axiosGetCallHeaderWithToken(
      `${Base_URL}/label_print/getStickerPdf?type=${type}&url=${currentUrl}${idParam}${stickerDataParam}`,
      "application/pdf"
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const uploadStickerData = async (selectedSizeId, selectedFiles) => {
  let response;
  try {
    const formData = new FormData();
    formData.append("selectedSizeId", selectedSizeId);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }
    response = await axiosPostCallWithToken(
      `${Base_URL}/label_print/uploadStickerData`,
      formData
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const getSampleUploadFile = async (type) => {
  let response;
  try {
    response = await axiosGetCallHeaderWithToken(
      `${Base_URL}/label_print/getSampleCsv?type=${type}`,
      {responseType: "blob"}
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};
