import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/AddAPhoto";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import AddBoxIcon from '@mui/icons-material/AddBox';
import PreviewIcon from '@mui/icons-material/Preview';
import CButton from "../../../basecomp/components/CButton2";
import {
  fetchSizeOptions,
  fetchStickers,
  fetchPoOrMaterial,
  addSticker,
  updateSticker,
  deleteSticker,
  getStickerPdf,
} from "../model/PrintingModel";
import { AddPrintKeys, PrintViewTypes } from "../../assets/VarConstants";


const PrintingVM = () => {
  const navigate = useNavigate();
  const { handleToastOpen } = useToast();

  const [poOptions, setPoOptions] = useState([]);
  const [matOptions, setMatOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [stickerData, setStickerData] = useState({
    [AddPrintKeys.PO_KEY]: "",
    [AddPrintKeys.MAT_NO_KEY]: "",
    [AddPrintKeys.SIZE_KEY]: "",
    [AddPrintKeys.QUANTITY_KEY]: 1,
  });
  const [selectedSize, setSelectedSize] = useState();
  let isTableEdit = false;
  const [buttonJson, setButtonJson] = useState({
    add: {
      text: "Add",
      tooltipText: "Add",
      isDisabled: false,
      src: <AddBoxIcon />
    },
    // "delete_all": {
    //     "text": "Delete All",
    //     "tooltipText": "Delete All Labels",
    //     isDisabled: false
    // },
    print: {
      text: "Print",
      tooltipText: "Print",
      isDisabled: false,
      src: <LocalPrintshopIcon />
    },
    view: {
      text: "View",
      tooltipText: "View",
      isDisabled: false,
      src: <PictureAsPdfIcon />
    },
    // "print_all": {
    //     "text": "Print all",
    //     "tooltipText": "Print All",
    //     isDisabled: false
    // }
  });
  const [openCarousel, setOpenCarousel] = useState(false);
  const [labelData, setLabelData] = useState([
    // {
    //   id: 1,
    //   material_no: "223eff",
    //   print_qty: 2,
    //   image_link: [
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRbKnG--JqNfXHh0OXOQgveASbG2ZDeOrpcQ&s",
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9yewYt6eXjPTdM4eICbf5ftrkyiDC9_4D2w&s",
    //   ],
    //   bin_location: "aaaaa",
    //   bulk_location: "alaaxa",
    //   status: 0,
    //   tableMode: "T",
    // },
    // {
    //   id: 2,
    //   material_no: "23epff",
    //   print_qty: 1,
    //   image_link: [],
    //   bin_location: "aaaaa",
    //   bulk_location: "alaaxa",
    //   status: 1,
    //   tableMode: "T",
    // },
  ]);
  const [carouselImages, setCarouselImages] = useState([]);
  let tableStickerDataGlobal = {};

  const fetchAllStickers = async () => {
    try {
      const response = await fetchStickers();
      const updatedStickers = response.data.map((obj) => ({
        ...obj,
        tableMode: "T",
      }));
      setLabelData(updatedStickers);
    } catch (error) {
      //handleToastOpen("error", error.message);
      setLabelData([]);
    }
  };

  useEffect(() => {
    if (stickerData[AddPrintKeys.PO_KEY] !== "") {
      handlePOEdit(stickerData[AddPrintKeys.PO_KEY]);
    }
  }, [stickerData[AddPrintKeys.PO_KEY]]);
  
  useEffect(() => {
    if (stickerData[AddPrintKeys.MAT_NO_KEY] !== "") {
      handleMaterialEdit(stickerData[AddPrintKeys.MAT_NO_KEY]);
    }
  }, [stickerData[AddPrintKeys.MAT_NO_KEY]]);
  

  useEffect(() => {
    const fetchSize = async () => {
      try {
        const response = await fetchSizeOptions();
        setSizeOptions(response.data);
        if (response.data.length === 1) {
          //console.log(response.data[0]);
          //stickerData[AddPrintKeys.SIZE_KEY] = response.data[0].id;
          //setSelectedSize(response.data[0].size);
        }
      } catch (error) {
        handleToastOpen("error", error.message);
        setSizeOptions([]);
      }
    };

    fetchSize();
    fetchAllStickers();
  }, []);

  const handleEditClick = async (data, index) => {
    console.log("labelData[index]  ", tableStickerDataGlobal, index);
    if (data.tableMode === "E") {
      try {
        const response = await updateSticker(
          tableStickerDataGlobal.id,
          tableStickerDataGlobal.material_no,
          tableStickerDataGlobal.bin_location === null ? "" : tableStickerDataGlobal.bin_location,
          tableStickerDataGlobal.bulk_location === null ? "" : tableStickerDataGlobal.bulk_location,
          tableStickerDataGlobal.print_qty
        );
        const updatedData = response.data;
        isTableEdit = false;
        setLabelData((prevLabelData) => {
          const updatedLabelData = [...prevLabelData];
          updatedLabelData[index].tableMode = "T";
          updatedLabelData[index].material_no = updatedData.material_no;
          updatedLabelData[index].material_status = updatedData.material_status;
          updatedLabelData[index].bin_location = updatedData.bin_location;
          updatedLabelData[index].bin_loc_status = updatedData.bin_loc_status;
          updatedLabelData[index].bulk_location =
            updatedData.bulk_location;
          updatedLabelData[index].print_qty = updatedData.print_qty;
          return updatedLabelData;
        });
      } catch (error) {
        handleToastOpen("error", error.message);
      }
    } else {
      if (isTableEdit === false) {
        isTableEdit = true;//state was not working, used a variable
        const updatedLabelData = { ...data };
        updatedLabelData.tableMode = "E";
        tableStickerDataGlobal = updatedLabelData;//state was not working, used a variable
        setLabelData((prevLabelData) => {
          const updatedLabelData = [...prevLabelData];
          updatedLabelData[index].tableMode = "E";
          return updatedLabelData;
        });
      }
    }
  };

  const handlePOEdit = async (value) => {
    try {
      if (value === "") {
        setMatOptions([]);
      } else if (stickerData[AddPrintKeys.MAT_NO_KEY] === "") {
        const response = await fetchPoOrMaterial(value, "");
        setMatOptions(response.data);
      }
    } catch (error) {
      //handleToastOpen("error", error.message);
    }
  };

  const handleMaterialEdit = async (value) => {
    try {
      if (value === "") {
        setPoOptions([]);
      } else if (stickerData[AddPrintKeys.PO_KEY] === "") {
        const response = await fetchPoOrMaterial("", value);
        setPoOptions(response.data);
      }
    } catch (error) {
      //handleToastOpen("error", error.message);
    }
  };

  const handleStickerDataEdit = (key) => (value) => {
    setStickerData((prevStickerData) => ({
      ...prevStickerData,
      [key]: (typeof value === "string" && value !== null ? value.trim() : value),
    }));
  
    if (key === AddPrintKeys.MAT_NO_KEY) {
      handleMaterialEdit(value);
  
      // Reset material_no when po_no changes
      setStickerData((prevStickerData) => ({
        ...prevStickerData,
        [AddPrintKeys.PO_KEY]: "",
      }));
      setPoOptions([]);
    } else if (key === AddPrintKeys.PO_KEY) {
      handlePOEdit(value);
    }
  };
  

  const handleTableEditClick = (key) => (value) => {
    tableStickerDataGlobal[key] = value;
  };

  const handleSizeChange = (value) => {
    if (value === null || value === "") {
      handleStickerDataEdit(AddPrintKeys.SIZE_KEY)("");
    } else {
      handleStickerDataEdit(AddPrintKeys.SIZE_KEY)(value.id);
    }
  };

  const validateStickers = () => {
    if (stickerData[AddPrintKeys.MAT_NO_KEY] === "" ||
      stickerData[AddPrintKeys.SIZE_KEY] === "" ||
      stickerData[AddPrintKeys.QUANTITY_KEY] === "") {
      handleToastOpen("error", "Please enter the required fields");
      return false;
    }
    return true;
  }

  const addStickerData = async () => {
    try {
      const response = await addSticker(stickerData);
      handleToastOpen("success", response.data.message);
      await fetchAllStickers();
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  const deleteStickerData = async (id) => {
    try {
      const response = await deleteSticker(id);
      handleToastOpen("success", response.data.message);
      
      // Reset the edit state and clear any global variables or state
      isTableEdit = false;
      tableStickerDataGlobal = {};
      
      // Fetch updated stickers
      await fetchAllStickers();
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };
  

  const getFormattedDateTime = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const currentTime = currentDate.toLocaleTimeString();
    return formattedDate + "_" + currentTime;
  };

  const pdfPrintView = async (id, stickerData, isPrint, type) => {
    try {
      const response = await getStickerPdf(id, stickerData, type);
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      if (isPrint) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `label_list_${getFormattedDateTime()}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  const handleButtonClick = (key) => {
    if (key === "add") {
      const isValidated = validateStickers();
      if (isValidated) {
        addStickerData();
      }
    } else if (key === "delete_all") {
      deleteStickerData();
    } else if (key === "print") {
      const isValidated = validateStickers();
      if (isValidated) {
        pdfPrintView(undefined, stickerData, true, PrintViewTypes.PRINT_VIEW_DATA);
      }
    } else if (key === "view") {
      const isValidated = validateStickers();
      if (isValidated) {
        pdfPrintView(undefined, stickerData, false, PrintViewTypes.PRINT_VIEW_DATA);
      }
    } else if (key === "print_all") {
      pdfPrintView(undefined, undefined, true, PrintViewTypes.PRINT_VIEW_ALL);
    }
  };

  const handleImageClick = (actualRow) => {
    const hasNoImages = actualRow.image_link.length === 0;
    console.log("hasNoImages:  ", hasNoImages, actualRow, actualRow.image_link);
    if (hasNoImages) {
      navigate(`/warehouse/image-upload?mat_no=${actualRow.material_no}`);
    } else {
      setCarouselImages(actualRow.image_link);
      setOpenCarousel(true);
    }
  };

  const infoHeadData = [
    "Material Number",
    "Print Quantity",
    "Images",
    "Bin Location",
    "Bulk Location",
    "Edit",
    <CButton
      cSrc={<DeleteIcon />}
      cTooltipText="Delete All"
      cOnClick={() => handleButtonClick("delete_all")}
    />,
    "Preview",
    <CButton
      cSrc={<LocalPrintshopIcon />}
      cTooltipText="Print All"
      cOnClick={() => handleButtonClick("print_all")}
    />,
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: `material_no`,
      tooltipText: (actualRow) =>
      (actualRow.material_status === 0
        ? "Material Number not present in SAP"
        : "Material Number present in SAP"),
      status: `material_status`,
      type: "M",
      actualType: (actualRow) => actualRow.tableMode,
      actionV: (value) => handleTableEditClick(AddPrintKeys.MAT_NO_KEY)(value),
    },
    {
      value: `print_qty`,
      status: `material_status`,
      type: "M",
      eType: "quantity",
      actualType: (actualRow) => actualRow.tableMode,
      actionV: (value) => handleTableEditClick(AddPrintKeys.QUANTITY_KEY)(value),
    },
    {
      value: (actualRow) =>
        actualRow.image_link.length === 0 ? (
          <CameraAltIcon />
        ) : (
          <PreviewIcon />
        ),
      tooltipText: (actualRow) =>
        actualRow.image_link.length === 0
          ? "Upload Material Images"
          : "View Material Images",
      type: "IC",
      actionV: (actualRow) => handleImageClick(actualRow),
    },
    {
      value: `bin_location`,
      status: `material_status`,
      tooltipText: (actualRow) =>
        (actualRow.sap_bin_status === 0
          ? "Bin Location present in SAP"
          : "Bin Location not present in SAP"),
      type: "M",
      actualType: (actualRow) =>
        actualRow.bin_loc_status === 1 ? "T" : actualRow.tableMode,
      actionV: (value) => handleTableEditClick(AddPrintKeys.BIN_LOC_KEY)(value),
    },
    {
      value: `bulk_location`,
      status: `material_status`,
      type: "M",
      actualType: (actualRow) => actualRow.tableMode,
      actionV: (value) => handleTableEditClick(AddPrintKeys.BULK_LOC_KEY)(value),
    },
    {
      value: (actualRow) =>
        actualRow.tableMode === "T" ? <EditIcon /> : <SaveIcon />,
      tooltipText: (actualRow) =>
        actualRow.tableMode === "T" ? "Edit" : "Save",
      type: "IC",
      actionV: (actualRow, index) => handleEditClick(actualRow, index),
    },
    {
      value: <DeleteIcon />,
      tooltipText: "Delete",
      type: "I",
      actionV: (actualRow) => deleteStickerData(actualRow.id),
    },
    {
      value: <PictureAsPdfIcon />,
      tooltipText: "View",
      status: `material_status`,
      type: "I",
      actionV: (actualRow) => pdfPrintView(actualRow.id, undefined, false, PrintViewTypes.PRINT_VIEW_ID),
    },
    {
      value: <LocalPrintshopIcon />,
      tooltipText: "Print",
      status: `material_status`,
      type: "I",
      actionV: (actualRow) => pdfPrintView(actualRow.id, undefined, true, PrintViewTypes.PRINT_VIEW_ID),
    },
  ]);

  return {
    poOptions,
    matOptions,
    sizeOptions,
    selectedSize,
    stickerData,
    handleStickerDataEdit,
    buttonJson,
    labelData,
    handleSizeChange,
    handleButtonClick,
    infoHeadData,
    infoRowData,
    carouselImages,
    openCarousel,
    setOpenCarousel,
  };
};

export default PrintingVM;