import { useState, useEffect } from "react";
import { getPlantOptions, submitFormData } from "../model/GSMainPage";
import Logger from "../../../basecomp/base/Logger";
import { getSparePartDetails } from "../model/GSMainPage";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

function useRegistrationDataViewModel() {
  const { handleToastOpen } = useToast();
  const [plantOptions, setPlantOptions] = useState([]);
  const [material, setMaterial] = useState("");
  const [materialCheckResult, setMaterialCheckResult] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [additionalFieldName, setAdditionalFieldName] = useState("");
  const [selectedObjImg, setSelectedObjImg] = useState(null);
  const [selectedAssetImg, setSelectedAssetImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const userTerminal=localStorage.getItem('terminal')

  const handleImageUploadObj = (event) => {
    setSelectedObjImg(event.target.files[0]);

    console.log("selectedObjImg", selectedObjImg);
  };

  useEffect(() => {
    console.log("Selected Object Image (in useEffect):", selectedObjImg);
  }, [selectedObjImg]);

  const handleImageUploadAsset = (event) => {
    setSelectedAssetImg(event.target.files[0]);

    console.log("selectedAssetImg", selectedAssetImg);
  };

  useEffect(() => {
    console.log("Selected Asset Image (in useEffect):", selectedAssetImg);
  }, [selectedAssetImg]);

  useEffect(() => {
    fetchPlantOptions();
  }, []);

  const fetchPlantOptions = async () => {
    setLoading(true);
    try {
      const response = await getPlantOptions();
      setPlantOptions(response.data);
    } catch (error) {
      Logger.printStackTrace(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (material) {
      MaterialData();
    }
  }, [material]);

  const MaterialData = async () => {
    setLoading(true);
    try {
      const response = await getSparePartDetails(material);

      if (response.data && response.data[0] && response.data[0].material_desc) {
        setMaterialCheckResult(response.data[0]);
      } else {
        setMaterialCheckResult(null);
      }
    } catch (error) {
      setMaterialCheckResult(null);
      Logger.printStackTrace(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOption1Change = (event) => {
    setOption1(event.target.value);
  };

  const handleOption2Change = (event) => {
    setOption2(event.target.value);
    setShowAdditionalFields(
      event.target.value === "option1" || event.target.value === "option2"
    );
    setAdditionalFieldName(
      event.target.value === "option1" ? "Fuel Asset ID" : "Material Number"
    );
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log("Selected Asset Image:", selectedAssetImg);

    const assetId = document.getElementById("asset-id").value;
    const formData = new FormData();

    formData.append("id", assetId);
    formData.append("terminal", option1);
    formData.append("type", option2 === "option1" ? "F" : "S");

    if (option2 === "option1") {
      formData.append(
        "fuel_asset_id",
        document.getElementById("fuel-asset-id").value
      );
      formData.append(
        "fuel_asset_name",
        document.getElementById("fuel-asset-name").value
      );
    } else if (option2 === "option2") {
      formData.append("material_no", material);
    }

    if (selectedObjImg) {
      formData.append("objImg", selectedObjImg);
    }

    if (selectedAssetImg) {
      // Now you can access the selectedAssetImg state here
      console.log("Selected Asset Image if:", selectedAssetImg);

      formData.append("assetImg", selectedAssetImg);
    }

    try {
      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      const response = await submitFormData(formData);
      handleToastOpen("success", response.data.message);
      if(response.data.message){
        window.location.reload();
      }
    } catch (error) {
      Logger.printStackTrace(error);
      if (error.response && error.response.data) {
        Logger.printStackTrace(error);
      } else {
        Logger.printStackTrace(error);
      }
    }  finally {
      setLoading(false);
    }
  };

  return {
    plantOptions,
    option1,
    option2,
    showAdditionalFields,
    material,
    materialCheckResult,
    additionalFieldName,
    handleOption1Change,
    handleOption2Change,
    setMaterial,
    handleSubmit,
    handleImageUploadObj,
    handleImageUploadAsset,
  };
}

export default useRegistrationDataViewModel;
