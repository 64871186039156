import { useState, useEffect } from "react";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import {
  fetchSizeOptions,
  getSampleUploadFile,
  uploadStickerData,
} from "../model/PrintingModel";

const UploadLabelVM = () => {
  const { handleToastOpen } = useToast();
  const [sizeOptions, setSizeOptions] = useState([]);
  const [selectedSizeId, setSelectedSizeId] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    const fetchSize = async () => {
      try {
        const response = await fetchSizeOptions();
        setSizeOptions(response.data);
      } catch (error) {
        handleToastOpen("error", error.message);
        setSizeOptions([]);
      }
    };
    fetchSize();
  }, []);

  const handleSizeChange = (value) => {
    setSelectedSizeId(value.id);
  };

  const handleSampleFileDownload = async () => {
    try {
      const response = await getSampleUploadFile("label");
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `SampleLabelUpload.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    }
  };

  const handleFileChoose = (files) => {
    setSelectedFiles(files);
  };

  const handleLabelFileUpload = async () => {
    try {
      const response = await uploadStickerData(selectedSizeId, selectedFiles);
      handleToastOpen("success", response.data.message);
      if (response.data.message) {
        window.location.reload();
      }
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  return {
    sizeOptions,
    handleSizeChange,
    handleSampleFileDownload,
    handleFileChoose,
    handleLabelFileUpload,
  };
};

export default UploadLabelVM;
