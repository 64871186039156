import { useEffect, useState } from "react";
import {
  getDownloadExportFile,
  fetchBinLocationDetails,
  binLocationUploadCheck,
  uploadBinLocData,
} from "../model/BinLocation";
import {
  DATE_CHECK,
  DATE_REQUIRED,
  FILE_DOWNLOAD_FAILED,
  FILE_DOWNLOAD_SUCCESS,
  NO_DATA,
} from "../model/Strings";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import Logger from "../../../basecomp/base/Logger";
import { getSampleUploadFile } from "../model/PrintingModel";

const BinLocationVM = () => {
  const { handleToastOpen } = useToast();
  const [binLocationData, setBinLocationData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminBinUploadAccess, setAdminBinUploadAccess] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    handleBinLocUploadCheck();
  }, []);

  const handleBinLocUploadCheck = async () => {
    try {
      const response = await binLocationUploadCheck();
      if (response.data.includes(31)) {
        setAdminBinUploadAccess(true);
      }
    } catch (error) {
      Logger.printStackTrace(error);
    }
  };
  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  let startDateObj = startDate ? new Date(startDate) : null;
  let endDateObj = endDate ? new Date(endDate) : null;

  const getBinLocationDetails = async () => {
    setLoading(true);
    try {
      if (startDate && isNaN(startDateObj.getTime())) {
        startDateObj = null;
      }
      if (endDate && isNaN(endDateObj.getTime())) {
        endDateObj = null;
      }

      if (!startDateObj && !endDateObj) {
        const response = await fetchBinLocationDetails(null, null);
        setBinLocationData(response.data);
      } else if (startDateObj && endDateObj) {
        startDateObj.setHours(0, 0, 0, 0);
        endDateObj.setHours(23, 59, 59, 999);

        if (startDateObj <= endDateObj) {
          const response = await fetchBinLocationDetails(
            startDateObj,
            endDateObj
          );
          setBinLocationData(response.data);
        } else {
          handleToastOpen("error", DATE_CHECK);
        }
      } else {
        if (startDateObj || endDateObj) {
          handleToastOpen("error", DATE_REQUIRED);
        } else {
          const response = await fetchBinLocationDetails(null, null);
          setBinLocationData(response.data);
        }
      }
    } catch (error) {
      handleToastOpen("error", NO_DATA);
    } finally {
      setLoading(false);
    }
  };

  const downloadExportAllFile = async () => {
    setLoading(true);
    try {
      const response = await getDownloadExportFile(startDateObj, endDateObj);
      const blob = new Blob([response.data]);
      const currentDate = new Date().toLocaleString("sv-SE").replace("T", " ");
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `SAP-Bin-Location-Data-${currentDate}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", FILE_DOWNLOAD_SUCCESS);
    } catch (error) {
      handleToastOpen("error", FILE_DOWNLOAD_FAILED);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChoose = (files) => {
    setSelectedFiles(files);
  };

  const handleBinLocFileUpload = async () => {
    setLoading(true);
    try {
      const response = await uploadBinLocData(selectedFiles);
      handleToastOpen("success", response.data.message);
    } catch (error) {
      handleToastOpen("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSampleFileDownload = async () => {
    try {
      const response = await getSampleUploadFile("bin_location");
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `SampleBinLocationUpload.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    }
  };

  return {
    downloadExportAllFile,
    getBinLocationDetails,
    binLocationData,
    handleStartDateChange,
    handleEndDateChange,
    loading,
    adminBinUploadAccess,
    handleFileChoose,
    handleBinLocFileUpload,
    handleSampleFileDownload
  };
};

export default BinLocationVM;
